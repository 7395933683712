import Header from "../components/common/Header";
import ContactForm from "../components/common/Contact";
import Footer from "../components/common/Footer";
import Career from "../components/biography/Career";
import Private from "../components/biography/Private";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet
        title="経歴"
        link={[
          { rel: "canonical", href: "https://matsuki-takeshi.com/biography" },
        ]}
        meta={[
          {
            name: "description",
            content:
              "Matsuki Takeshi 松木健 - バックエンドエンジニア |メインはPHP、Laravel、Pythonでのバックエンド開発。Vue.js、React.js、Next.js等を利用したフロントエンド開発。Terraform, AWS等を利用したインフラ構築。",
          },
          {
            property: "og:description",
            content:
              "Matsuki Takeshi 松木健 - バックエンドエンジニア |メインはPHP、Laravel、Pythonでのバックエンド開発。Vue.js、React.js、Next.js等を利用したフロントエンド開発。Terraform, AWS等を利用したインフラ構築。",
          },
          { property: "og:title", content: "経歴" },
          { property: "og:type", content: "経歴紹介" },
          {
            property: "og:url",
            content: "https://matsuki-takeshi.com/biography",
          },
          {
            property: "keywords",
            content: "エンジニア, バックエンド, フリーランス",
          },
        ]}
      />
      <header className="App-header">
        <Header />
      </header>
      <div>
        <div>
          <h1 style={{ fontSize: 26, textAlign: "center" }}>My Biography</h1>

          <h2 style={{ textAlign: "center" }}>Private</h2>

          <Private />

          <h2 style={{ textAlign: "center" }}>Career & Projects</h2>

          <Career />

          <h2 style={{ textAlign: "center" }}>Contact</h2>

          <ContactForm />

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
