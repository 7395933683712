import Header from "../components/common/Header";
import ProjectCard from "../components/home/ProfileCard";
import SkillSet from "../components/home/SkillSet";
import ContactForm from "../components/common/Contact";
import Footer from "../components/common/Footer";
import { Helmet } from "react-helmet";
import Portfolio from "../components/home/Portfolio";

function App() {
  return (
    <div className="App">
      <Helmet
        title="Matsuki Takeshi | 松木健"
        link={[{ rel: "canonical", href: "https://matsuki-takeshi.com/" }]}
        meta={[
          {
            name: "description",
            content:
              "Matsuki Takeshi 松木健 - バックエンドエンジニア |メインはPHP、Laravel、Pythonでのバックエンド開発。Vue.js、React.js、Next.js等を利用したフロントエンド開発。Terraform, AWS等を利用したインフラ構築。",
          },
          {
            property: "og:description",
            content:
              "Matsuki Takeshi 松木健 - バックエンドエンジニア |メインはPHP、Laravel、Pythonでのバックエンド開発。Vue.js、React.js、Next.js等を利用したフロントエンド開発。Terraform, AWS等を利用したインフラ構築。",
          },
          { property: "og:title", content: "Matsuki Takeshi | 松木健" },
          { property: "og:type", content: "自己紹介" },
          { property: "og:url", content: "https://matsuki-takeshi.com/" },
          { property: "keywords", content: "エンジニア, フリーランス, 松木健" },
        ]}
      />
      <header className="App-header">
        <Header />
      </header>
      <div>
        <div>
          <h1 style={{ fontSize: 26, textAlign: "center" }}>
            Matsuki Takeshi / 松木 健
          </h1>

          <h2 style={{ textAlign: "center" }}>Profile</h2>

          <ProjectCard />

          <h2 style={{ textAlign: "center" }}>Skill Set</h2>

          <SkillSet />
          
          <h2 style={{ textAlign: "center" }}>Portfolio</h2>

          <Portfolio />

          <h2 style={{ textAlign: "center" }}>Contact</h2>

          <ContactForm />

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
