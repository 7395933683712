import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

export default function FormPropsTextFields() {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [content, setContent] = useState("");
  const [email, setEmail] = useState("");

  const [nameError, setNameError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [contentError, setContentError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState("");
  const [contentErrorMessage, setContentErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [isDone, setIsDone] = useState(false);
  const [isDoing, setIsDoing] = useState(false);

  const submitContents = () => {
    const hasError = validate();

    if (hasError) {
      setIsDoing(false);
      return;
    }

    sendEmail();
    setIsDoing(false);
  };

  const hasError = () => {
    return nameError || companyNameError || contentError || emailError;
  };

  const validate = () => {
    const hasErrorName = validateName(name);
    const hasErrorCompanyName = validateComapnyName(companyName);
    const hasErrorContent = validateContent(content);
    const hasErrorEmail = validateEmail(email);

    if (hasErrorName) {
      return true;
    }

    if (hasErrorCompanyName) {
      return true;
    }

    if (hasErrorContent) {
      return true;
    }

    if (hasErrorEmail) {
      return true;
    }
  };

  const validateName = (name: string) => {
    setNameErrorMessage("");
    setNameError(false);

    if (name === "") {
      setNameError(true);
      setNameErrorMessage("氏名を入力してください。");
      return true;
    }

    if (name.length > 100) {
      setNameError(true);
      setNameErrorMessage("氏名は100文字以内で入力してください。");
      return true;
    }
  };

  const validateComapnyName = (companyName: string) => {
    setCompanyNameErrorMessage("");
    setCompanyNameError(false);

    if (companyName.length > 100) {
      setCompanyNameError(true);
      setCompanyNameErrorMessage("会社名は100文字以内で入力してください。");
      return true;
    }
  };

  const validateContent = (content: string) => {
    setContentErrorMessage("");
    setContentError(false);

    if (content === "") {
      setContentError(true);
      setContentErrorMessage("ご用件を入力してください。");
      return true;
    }

    if (content.length > 1000) {
      setContentError(true);
      setContentErrorMessage("ご用件は1000文字以内で入力してください。");
      return true;
    }
  };

  const validateEmail = (email: string) => {
    setEmailErrorMessage("");
    setEmailError(false);

    if (email === "") {
      setEmailError(true);
      setEmailErrorMessage("メールアドレスを入力してください。");
      return true;
    }

    if (email.length > 100) {
      setEmailError(true);
      setEmailErrorMessage("メールアドレスは100文字以内で入力してください。");
      return true;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage("メールアドレスの形式が正しくありません。");
      return true;
    }
  };

  const handleNameChange = (name: string) => {
    setName(name);
    validateName(name);
  };

  const handleCompanyNameChange = (companyName: string) => {
    setCompanyName(companyName);
    validateComapnyName(companyName);
  };

  const handleContentChange = (content: string) => {
    setContent(content);
    validateContent(content);
  };

  const handleEmailChange = (email: string) => {
    setEmail(email);
    validateEmail(email);
  };

  const sendEmail = () => {
    const param = {
      name: name,
      companyName: companyName,
      email: email,
      content: content,
    };

    emailjs
      .send("my_gmail", "contact_me", param, process.env.REACT_APP_EMAILJS_KEY)
      .then(
        () => {
          alert("送信完了しました。");
          setIsDone(true);
        },
        () => {
          alert(
            "送信に失敗しました。送信できない場合は各種SNSから直接ご連絡ください。"
          );
        }
      );
  };
  const { t } = useTranslation();

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: 350 },
        textAlign: "center",
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          error={nameError}
          required
          id="name"
          label={t("common.contact.name")}
          value={name}
          onChange={(e) => handleNameChange(e.target.value)}
          helperText={nameErrorMessage}
        />
      </div>
      <div>
        <TextField
          error={companyNameError}
          id="company_name"
          label={t("common.contact.companyName")}
          value={companyName}
          onChange={(e) => handleCompanyNameChange(e.target.value)}
          helperText={companyNameErrorMessage}
        />
      </div>
      <div>
        <TextField
          error={emailError}
          id="email"
          required
          label={t("common.contact.email")}
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          helperText={emailErrorMessage}
        />
      </div>
      <div>
        <TextField
          error={contentError}
          required
          id="content"
          label={t("common.contact.content")}
          value={content}
          onChange={(e) => handleContentChange(e.target.value)}
          helperText={contentErrorMessage}
          multiline
          maxRows={8}
          minRows={4}
        />
      </div>
      <Button
        disabled={hasError() || isDone || isDoing}
        variant="contained"
        onClick={() => submitContents()}
      >
        Contact
      </Button>
    </Box>
  );
}
