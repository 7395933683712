import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";

export default function HowTo() {
    const { t } = useTranslation();
    const CardCounts = 3;

    return (
        <>
            {(function () {
                const list = [];
                for (let i = 0; i < CardCounts; i++) {
                list.push(
                    <Card
                    sx={{ maxWidth: 450, margin: "0 auto", my: 5, display: "flex", p: 3 }}
                    >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography component="div" variant="h5">
                            {t("picdict.how_to." + i + ".title")}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                        >
                            {t("picdict.how_to." + i + ".detail")}
                        </Typography>
                        </CardContent>
                    </Box>
                    <CardMedia
                        component="img"
                        sx={{ width: "30%", ml: "auto", objectFit: "contain" }}
                        image={t("picdict.how_to." + i + ".img")}
                        alt="picdict"
                    />
                    </Card>);
                }
                return <div>{list}</div>;
            })()}
        </>
  );
}
