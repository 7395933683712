import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function MediaControlCard() {
  const { t } = useTranslation();

  return (
    <Card
      sx={{ maxWidth: 400, margin: "0 auto", my: 5, display: "flex", p: 3 }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h6">
            Matsuki Takeshi
          </Typography>
          <Typography component="div" variant="h5">
            松木 健
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {t("home.position")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t("home.profile")}
          </Typography>
          <div style={{ display: "flex" }}>
            <a
              href="https://github.com/matsukitakeshi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CardMedia
                component="img"
                sx={{ width: 20, ml: "auto" }}
                image={`${process.env.PUBLIC_URL}/github.webp`}
                alt="linkedin"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/takeshi-matsuki/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: "2px" }}
            >
              <CardMedia
                component="img"
                sx={{ width: 20, ml: "auto" }}
                image={`${process.env.PUBLIC_URL}/linkedin.webp`}
                alt="linkedin"
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100011542213360"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: "2px" }}
            >
              <CardMedia
                component="img"
                sx={{ width: 20, ml: "auto" }}
                image={`${process.env.PUBLIC_URL}/facebook.webp`}
                alt="facebook"
              />
            </a>
            <Link to="/biography" style={{ marginLeft: "auto" }}>
              <Button sx={{ height: 30 }} variant="text">
                Bio
              </Button>
            </Link>
          </div>
        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: "41%", ml: "auto", objectFit: "contain" }}
        image={`${process.env.PUBLIC_URL}/matsuki.webp`}
        alt="matsuki takeshi"
      />
    </Card>
  );
}
