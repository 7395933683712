import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function BasicCard() {
  return (
    <div>
      <Card
        sx={{ maxWidth: 300, margin: "0 auto", my: 5, display: "flex", p: 3 }}
      >
        <CardContent sx={{ minWidth: 300, maxwidth: 400 }}>
          <Typography variant="h5" component="div">
            Backend
          </Typography>
          <Typography variant="body2">
            Python, PHP, Rust
            <br />
            Flask, Laravel
          </Typography>
        </CardContent>
      </Card>
      <Card
        sx={{ maxWidth: 300, margin: "0 auto", my: 5, display: "flex", p: 3 }}
      >
        <CardContent sx={{ minWidth: 300, maxwidth: 400 }}>
          <Typography variant="h5" component="div">
            Frontend
          </Typography>
          <Typography variant="body2">
            JavaScript, TypeScript
            <br />
            Vue.js, React.js, Nuxt.js
          </Typography>
        </CardContent>
      </Card>
      <Card
        sx={{ maxWidth: 300, margin: "0 auto", my: 5, display: "flex", p: 3 }}
      >
        <CardContent sx={{ minWidth: 300, maxwidth: 400 }}>
          <Typography variant="h5" component="div">
            Others
          </Typography>
          <Typography variant="body2">
            AWS, Azure, Docker, Nginx, MySQL, PostgreSQL, Terraform, SAM
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
