import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { Link } from "react-router-dom";

export default function Portfolio() {
  const { t } = useTranslation();
  return (
    <Card
      sx={{ maxWidth: 450, margin: "0 auto", my: 5, display: "flex", p: 3 }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Link to="/picdict/about" style={{ marginLeft: "auto" }}>
            <Typography component="div" variant="h5">
              {t("home.portfolio.title")}
            </Typography>
          </Link>
          <br />
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {t("home.portfolio.description")}
          </Typography>
        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: "41%", ml: "auto", objectFit: "contain" }}
        image={`${process.env.PUBLIC_URL}/picdict.webp`}
        alt="picdict"
      />
    </Card>
  );
}
