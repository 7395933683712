import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

export default function BasicCard() {
  const { t } = useTranslation();

  const profileCardCounts = 9;

  return (
    <Trans>
      {(function () {
        const list = [];
        for (let i = 0; i < profileCardCounts; i++) {
          list.push(
            <Card
              sx={{
                maxWidth: 350,
                margin: "0 auto",
                my: 5,
                display: "flex",
                p: 3,
              }}
            >
              <CardContent sx={{ minWidth: 300, maxwidth: 400 }}>
                <div id={t("bio.career." + i + ".id")}>
                  <Typography variant="h5" component="div">
                    {t("bio.career." + i + ".date")}
                    <br />
                    {t("bio.career." + i + ".title")}
                  </Typography>
                </div>
                <Typography variant="body2">
                  {t("bio.career." + i + ".content")}
                </Typography>
              </CardContent>
            </Card>
          );
        }
        return <div>{list}</div>;
      })()}
    </Trans>
  );
}
